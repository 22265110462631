/* eslint-disable */
import { JsonRpcProvider } from '@ethersproject/providers'
import { ChainId } from '@pancakeswap/sdk'
import { blockstarTestnet , blockstarMainnet } from 'config/constants/exchange'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'

export const provider = (chainId) =>   {
  return new JsonRpcProvider(chainId === ChainId.BSC_TESTNET ? blockstarTestnet.rpcUrls.default.http[0] : blockstarMainnet.rpcUrls.default.http[0] )
}

const projectId = '27b9720018bf7f8740936e6a6eb28604'
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [blockstarMainnet,blockstarTestnet]
export const wagmiConfig:any = defaultWagmiConfig({ chains, projectId, metadata })


createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  enableAnalytics: true
})