import { Flex, Button, Text } from '@pancakeswap/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { useTranslation } from 'contexts/Localization'
import { useGasPriceManager } from 'state/user/hooks'
import { useNetwork } from 'wagmi'
import { GAS_PRICES, getChainId } from 'config/constants/exchange'
import { useEffect } from 'react'

const GasSettings = () => {
  const { t } = useTranslation()
  const [gasPrice, setGasPrice] = useGasPriceManager()
  const { chain } = useNetwork()
  const chainId = getChainId(chain);

  return (
    <Flex flexDirection="column">
      <Flex mb="12px" alignItems="center">
        <Text>{t('Default Transaction Speed (GWEI)')}</Text>
        <QuestionHelper
          text={t(
            'Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees.',
          )}
          placement="top-start"
          ml="4px"
        />
      </Flex>
      <Flex flexWrap="wrap">
        {GAS_PRICES && GAS_PRICES[chainId] && Object.keys(GAS_PRICES[chainId]).map((key) => {
          return (
            <Button
              mt="2px"
              mr="2px"
              scale="sm"
              onClick={() => {
                setGasPrice(GAS_PRICES[chainId][key]);
              }}
              variant={gasPrice === GAS_PRICES[chainId][key] ? 'primary' : 'tertiary'}
              key={key} // Updated key prop
            >
              {key} ({GAS_PRICES[chainId][key] / 10**9})
            </Button>
          );
        })}
      </Flex>
    </Flex>
  )
}

export default GasSettings
