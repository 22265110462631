import { ChainId } from '@pancakeswap/sdk'
import { Box, Text, UserMenu, UserMenuDivider, UserMenuItem } from '@pancakeswap/uikit'
import {  getChainId, blockstarTestnet, blockstarMainnet } from 'config/constants/exchange'
import { useTranslation } from 'contexts/Localization'

import Image from 'next/image'
import { setupNetwork } from 'utils/wallet'
import { useNetwork } from 'wagmi'


const chains = [blockstarTestnet,blockstarMainnet]
export const NetworkSelect = () => {
  const { t } = useTranslation()
  const img = {
    [ChainId.BSC]: '/images/tokens/chain-logo.png',
    [ChainId.BSC_TESTNET]: '/images/tokens/chain-logo.png',
  }
  return (
    <>
      <Box px="16px" py="8px">
        <Text>{t('Select a Network')}</Text>
      </Box>
      <UserMenuDivider />
      {chains.map((chain) => (
        <UserMenuItem key={chain.id} style={{ justifyContent: 'flex-start' }} onClick={() => setupNetwork(chain.id)}>
          <Image width={24} height={24} src={img[chain.id]} unoptimized />
          <Text pl="12px">{chain.name}</Text>
        </UserMenuItem>
      ))}
    </>
  )
}

export const NetworkSwitcher = () => {
  const { chain } = useNetwork();
  const img = {
    [ChainId.BSC]: '/images/tokens/chain-logo.png',
    [ChainId.BSC_TESTNET]: '/images/tokens/chain-logo.png',
  }
  let networkName
  if ( chain && chain.id && chain.id === blockstarTestnet.id) {
    networkName = blockstarTestnet.name
  } else {
    networkName = blockstarMainnet.name
  }

  return (
    <UserMenu mr="8px" avatarSrc={img[getChainId(chain)]} account={networkName} ellipsis={false}>
      {() => <NetworkSelect />}
    </UserMenu>
  )

  return null
}
