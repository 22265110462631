export const BSC_RPC_URLS = [
  'https://mainnet-rpc.blockstar.one',
]

export const BSC_TESTNET_RPC_URLS = [
  'https://testnet-rpc.blockstar.one',
  'https://testnet-rpc.blockstar.one',
  'https://testnet-rpc.blockstar.one',
]

export const STEALTH_TESTNET_RPC_URLS = [
  'https://testnet-rpc.blockstar.one'
]
